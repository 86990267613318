// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/constants.ts"
);
import.meta.hot.lastModified = "1733842577000";
}
// REMIX HMR END

import { GlobalFlag, RsvAttendance } from './generated/graphql'

export const APP_META_TITLE = 'Metrix Default School Admin'
export const APP_META_DESCRIPTION = 'A headless commerce storefront by Metrix'
export const DEV_API_URL = 'http://localhost:4300/admin-api'
export const DEMO_API_URL = 'https://readonly.yourmetrix.com/admin-api'

export const DateFilterOptions = [
  {
    id: 'year',
    name: 'This year',
  },
  {
    id: 'month',
    name: 'This month',
  },
  {
    id: 'week',
    name: 'This week',
  },
]

export const ParticipantActionMenuItems = [
  {
    label: 'Edit',
    value: 'edit',
  },
]

export const BeltColors = [
  {
    label: 'Purple Belt',
    value: 'purple',
  },
  {
    label: 'Blue Belt',
    value: 'blue',
  },
  {
    label: 'Green Belt',
    value: 'green',
  },
  {
    label: 'Orange Belt',
    value: 'orange',
  },
]

export const ParticipantOrderbyOptions = [
  {
    id: 'name(ascending)',
    name: 'Name (Ascending)',
  },
  {
    id: 'name(descending)',
    name: 'Name (Descending)',
  },
  {
    id: 'age(ascending)',
    name: 'Age (Ascending)',
  },
  {
    id: 'age(descending)',
    name: 'Age (Descending)',
  },
]

export const ClassInstanceParticipantsBulkActions = [
  {
    value: RsvAttendance.Present,
    label: 'Mark as Present',
  },
  {
    value: RsvAttendance.Unknown,
    label: 'Mark as Unknown',
  },
  {
    value: RsvAttendance.NoShow,
    label: 'Mark as NoShow',
  },
  {
    value: RsvAttendance.Cancelled,
    label: 'Mark as Cancelled',
  },
  {
    value: 'Stripe',
    label: 'Change Stripe',
  },
]

export const PaymentIntervalOptions = [
  {
    label: 'One time payment (no interval)',
    value: 'upfront',
  },
  {
    label: 'Bi-weekly',
    value: 'bi-weekly',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Yearly',
    value: 'yearly',
  },
]

export const FilterOperators = [
  { label: 'Starts With', value: 'startsWith' },
  { label: 'Ends With', value: 'endsWith' },
  { label: 'Contains', value: 'contains' },
  { label: 'Does Not Contain', value: 'doesNotContain' },
]

export enum CollectionFilterType {
  FACET_VALUE_FILTER = 'facet-value-filter',
  VARIANT_NAME_FILTER = 'variant-name-filter',
  VARIANT_ID_FILTER = 'variant-id-filter',
  PRODUCT_ID_FILTER = 'product-id-filter',
}

export enum PromotionConditionType {
  MINIMUM_ORDER_AMOUNT = 'minimum_order_amount',
  AT_LEAST_N_WITH_FACETS = 'at_least_n_with_facets',
  CONTAINS_PRODUCTS = 'contains_products',
  CUSTOMER_GROUP = 'customer_group',
  BUY_X_GET_Y_FREE = 'buy_x_get_y_free',
  SIBLING_DISCOUNT_CONDITION = 'sibling_discount_condition',
}

export enum PromotionActionType {
  ORDER_FIXED_DISCOUNT = 'order_fixed_discount',
  ORDER_PERCENTAGE_DISCOUNT = 'order_percentage_discount',
  FACET_BASED_DISCOUNT = 'facet_based_discount',
  PRODUCTS_PERCENTAGE_DISCOUNT = 'products_percentage_discount',
  FREE_SHOPPING = 'free_shipping',
  BUY_X_GET_Y_FREE = 'buy_x_get_y_free',
  SIBLING_DISCOUNT_ACTION = 'sibling_discount_action',
  SUBSCRIPTION_DISCOUNT_ACTION = 'subscription_discount_action',
  VARIANT_DISCOUNT_ACTION = 'variant_discount_action',
}

export const trackInventoryOptions = [
  {
    label: 'Track',
    value: GlobalFlag.True,
  },
  {
    label: 'Do not track',
    value: GlobalFlag.False,
  },
  {
    label: 'Inherit from global settings',
    value: GlobalFlag.Inherit,
  },
]
export const RsvAttendanceStatusList = [
  RsvAttendance.Unknown,
  RsvAttendance.Present,
  RsvAttendance.NoShow,
  RsvAttendance.Cancelled,
]
